import React from "react";
import { ThumbsUp, ThumbsDown } from "lucide-react";
import { useTranslation } from "react-i18next";

const ImportanceOfCustomerReviews = () => {
  const { t } = useTranslation();
  const positive = t("importanceOfCustomerReviews.positive.items", { returnObjects: true });
  const negative = t("importanceOfCustomerReviews.negative.items", { returnObjects: true });

  return (
    <section id="importance" className="w-full py-12 md:py-24 lg:py-32">
      <div className="container px-4 md:px-6">
        <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">
          {t("importanceOfCustomerReviews.title")}
        </h2>
        <div className="grid gap-6 lg:grid-cols-2">
          <div className="border rounded-lg shadow-lg p-6">
            <div className="flex flex-col mb-4">
              <ThumbsUp className="h-8 w-8 text-primary mr-2" />
              <h3 className="text-left text-2xl font-bold">{t("importanceOfCustomerReviews.positive.title")}</h3>
            </div>
            <ul className="text-left list-disc pl-5 space-y-2 text-gray-500">
              {positive.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <div className="border rounded-lg shadow-lg p-6">
            <div className="flex flex-col mb-4">
              <ThumbsDown className="h-8 w-8 text-primary mr-2" />
              <h3 className="text-left text-2xl font-bold">{t("importanceOfCustomerReviews.negative.title")}</h3>
            </div>
            <ul className="text-left list-disc pl-5 space-y-2 text-gray-500">
              {negative.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImportanceOfCustomerReviews;