import React from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const PaymentDetailsForm = ({
  isProcessing,
  setIsProcessing,
  onPaymentSuccess,
  onPaymentFailure,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const returnUrl = `${process.env.REACT_APP_FRONTEND_URL}/thank-you` || "http://localhost:3000/thank-you";
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Form submission detected");

    if (!stripe || !elements) {
      console.log("Stripe.js has not loaded");
      return;
    }

    setIsProcessing(true);
    console.log("Calling stripe.confirmPayment...");
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // return_url: returnUrl,
      },
      redirect: "if_required",
    });

    if (error) {
      console.error("Payment failed:", error.message);
      toast.error("Payment failed. Please try again.");
      if (onPaymentFailure) onPaymentFailure(error);
    } else if (paymentIntent?.status === "succeeded") {
      console.log("Payment successful:", paymentIntent);
      if (onPaymentSuccess) {
        console.log("Calling onPaymentSuccess...");
        onPaymentSuccess(paymentIntent);
      }
      // navigate("/thank-you");
    } else {
      console.log("Unexpected payment status:", paymentIntent?.status);
    }

    setIsProcessing(false);
  };

  return (
    <form
      onSubmit={(e) => {
        console.log("Form submission detected");
        handleSubmit(e);
      }}
    >
      <h2 className="font-cairo text-customGray2 font-bold uppercase mb-4">
        Payment Details
      </h2>
      <PaymentElement options={{}} />
      <div className="flex justify-between mt-4">
        <button
          type="submit"
          disabled={!stripe || isProcessing}
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          {isProcessing ? "Processing..." : "Pay Now"}
        </button>
      </div>
    </form>
  );
};

export default PaymentDetailsForm;
