import React, { useState, useEffect } from "react";
import { Shield, Star, TrendingUp } from "lucide-react";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import SuccessPopup from "../SuccessPopup";
import Testimonials from "./components/Testimonials";
import { FlagIcon } from "react-flag-kit";
import FinePrint from "../TermsConditions";
import PrivacyPolicy from "../PrivacyPolicy";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import Payment from "../Payment";

export default function LandingPage() {
  const { t, i18n } = useTranslation();
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const [packages, setPackages] = useState([]);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [formData, setFormData] = useState({
    package: "",
    name: "",
    email: "",
    message: "",
    amountReviews: 1,
  });
  const [clientSecret, setClientSecret] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const selectedPackage = packages.find((pkg) => pkg.name === formData.package);
  const selectedPackagePrice = selectedPackage ? selectedPackage.price : 0;

  const totalPrice =
  formData.package === "Elite Package"
    ? selectedPackagePrice * formData.amountReviews
    : formData.package === "Premium Package"
    ? selectedPackagePrice * formData.amountReviews
    : selectedPackagePrice;
    
  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/packages`
        );
        setPackages(response.data);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };

    fetchPackages();
  }, []);

  useEffect(() => {
    const fetchPaymentIntent = async () => {
      if (!totalPrice) {
        console.log("No package price selected yet.");
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/create-payment-intent`,
          {
            amount: Math.round(totalPrice * 100), // Convert to cents
            currency: "eur", // Adjust currency as needed
          }
        );

        if (response.data && response.data.clientSecret) {
          console.log(
            "Payment Intent Client Secret:",
            response.data.clientSecret
          );
          setClientSecret(response.data.clientSecret); // Assume `setClientSecret` is used for Stripe's clientSecret
        } else {
          console.error("No clientSecret received from backend.");
        }
      } catch (error) {
        console.error("Error fetching payment intent:", error.message);
      }
    };

    fetchPaymentIntent();
  }, [totalPrice]);

  const handlePaymentSuccess = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/seoEnquiry`,
        formData
      );
      console.log("Enquiry submitted successfully:", response.data);
      navigate("/thank-you"); // Redirect to a thank-you page
    } catch (error) {
      console.error("Error submitting enquiry:", error);
      toast.error("Failed to submit enquiry. Please contact support.");
    }
  };

  const handlePaymentFailure = (error) => {
    console.error("Payment failed:", error);
  };


  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };


  const [showPopup, setShowPopup] = useState(false);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    setFormData((prevFormData) => {
      // If "package" is being updated, reset amountReviews for specific packages
      if (name === "package") {
        return {
          ...prevFormData,
          [name]: value,
          amountReviews: value === "Premium Package" ? 6 : 1, // Default 6 for Premium, 1 otherwise
        };
      }
  
      // If "amountReviews" is being updated, parse it as an integer
      return {
        ...prevFormData,
        [name]: name === "amountReviews" ? parseInt(value, 10) : value,
      };
    });
  };


  const handleCheckout = async (e) => {
    e.preventDefault();
    setShowPaymentForm(true);
  };

  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <header className="mt-8 px-4 lg:px-6 h-14 flex items-center">
        <Link to="/" className="top-4 left-4 flex items-center space-x-2">
          <img
            src={logo}
            alt="Logo"
            className="h-36 w-auto object-contain hover:opacity-80 transition"
          />
        </Link>
        <nav className="ml-auto flex gap-4 sm:gap-6">
          <button
            onClick={() => changeLanguage("en")}
            className="focus:outline-none"
          >
            <FlagIcon code="GB" size={24} />
          </button>
          {/* German Flag */}
          <button
            onClick={() => changeLanguage("de")}
            className="focus:outline-none"
          >
            <FlagIcon code="DE" size={24} />
          </button>
          <a
            className="text-sm font-medium hover:underline underline-offset-4"
            href="#landingPageFeatures"
          >
            {t("landingPageFeatures.header")}
          </a>
          <a
            className="text-sm font-medium hover:underline underline-offset-4"
            href="#testimonials"
          >
            {t("testimonials.header")}
          </a>
          <a
            className="text-sm font-medium hover:underline underline-offset-4"
            href="#contact"
          >
            {t("contact.header")}
          </a>
        </nav>
      </header>

      {/* Main Content */}
      <main className="flex-1">
        {/* Hero Section */}
        <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 bg-gray-50">
          <div className="container mx-auto px-4 md:px-6 text-center">
            <div className="flex flex-col items-center space-y-4">
              <h1 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl lg:text-6xl text-gray-800">
                {t("hero.title")}
              </h1>
              <p className="max-w-[700px] text-gray-600 dark:text-gray-400 text-base md:text-xl">
                {t("hero.description")}
              </p>
              <div className="space-x-4">
                <button
                  onClick={() => {
                    const contactSection = document.getElementById("contact");
                    if (contactSection) {
                      contactSection.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                  {t("hero.getStarted")}
                </button>
                <button
                  onClick={() => navigate("/learn-more")}
                  className="px-4 py-2 border border-blue-500 text-blue-500 rounded-lg hover:bg-blue-100"
                >
                  {t("hero.learnMore")}
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section
          id="landingPageFeatures"
          className="w-full py-12 md:py-24 lg:py-32 bg-gray-100"
        >
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tight sm:text-5xl text-center mb-12 text-gray-800">
              {t("landingPageFeatures.header")}
            </h2>
            <div className="grid gap-8 lg:grid-cols-3">
              <div className="flex flex-col items-center text-center space-y-4">
                <Shield className="h-12 w-12 text-blue-500" />
                <h3 className="text-xl font-bold">
                  {t("landingPageFeatures.negativeReviewRemoval.title")}
                </h3>
                <p className="text-gray-600">
                  {t("landingPageFeatures.negativeReviewRemoval.description")}
                </p>
              </div>
              <div className="flex flex-col items-center text-center space-y-4">
                <Star className="h-12 w-12 text-blue-500" />
                <h3 className="text-xl font-bold">
                  {t("landingPageFeatures.reputationManagement.title")}
                </h3>
                <p className="text-gray-600">
                  {t("landingPageFeatures.reputationManagement.description")}
                </p>
              </div>
              <div className="flex flex-col items-center text-center space-y-4">
                <TrendingUp className="h-12 w-12 text-blue-500" />
                <h3 className="text-xl font-bold">
                  {t("landingPageFeatures.seoOptimization.title")}
                </h3>
                <p className="text-gray-600">
                  {t("landingPageFeatures.seoOptimization.description")}
                </p>
              </div>
            </div>
          </div>
        </section>

        <Testimonials />

        {/* Contact Section */}
        <section
          id="contact"
          className="w-full py-12 md:py-24 lg:py-32 bg-gray-100"
        >
          <div className="container mx-auto px-4 md:px-6">
            <div className="flex flex-col items-center text-center space-y-4">
              <h2 className="text-3xl font-bold tracking-tight sm:text-5xl text-gray-800">
                {t("contact.header")}
              </h2>
              <p className="max-w-lg text-gray-600">
                {t("contact.description")}
              </p>
              {!showPaymentForm ? (
                <form
                  onSubmit={handleCheckout}
                  className="flex flex-col space-y-4 w-full max-w-md"
                >
                  <select
                    name="package"
                    onChange={handleInputChange}
                    value={formData.package || ""}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                    required
                  >
                    <option value="" disabled>
                      {t("selectPackagePlaceholder")}
                    </option>
                    <option value="Basic Package">{t("package.basic")}</option>
                    <option value="Elite Package">{t("package.elite")}</option>
                    <option value="Premium Package">
                      {t("package.premium")}
                    </option>
                  </select>
                  {formData.package === "Elite Package" && (
                    <select
                      name="amountReviews"
                      value={formData.amountReviews || 1}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                      required
                    >
                      {[2, 3, 4, 5].map((number) => (
                        <option key={number} value={number}>
                          {number}
                        </option>
                      ))}
                    </select>
                  )}

                  {formData.package === "Premium Package" && (
                    <input
                      type="number"
                      name="amountReviews"
                      value={formData.amountReviews || 6}
                      onChange={handleInputChange}
                      min="6"
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                      placeholder={t("enterAmountReviews")}
                      required
                    />
                  )}
                  <input
                    type="text"
                    name="name"
                    onChange={handleInputChange}
                    placeholder={t("contact.namePlaceholder")}
                    value={formData.name}
                    className="w-full px-4 py-2 border rounded-lg"
                  />
                  <input
                    type="email"
                    name="email"
                    onChange={handleInputChange}
                    value={formData.email}
                    placeholder={t("contact.emailPlaceholder")}
                    className="w-full px-4 py-2 border rounded-lg"
                  />
                  <textarea
                    placeholder={t("contact.messagePlaceholder")}
                    name="message"
                    onChange={handleInputChange}
                    value={formData.message}
                    className="w-full px-4 py-2 border rounded-lg"
                  />
                  <p className="text-gray-600">
                    {t("totalPrice")}: €{totalPrice.toFixed(2)}
                  </p>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                  >
                    {t("proceedToPayment")}
                  </button>
                </form>
              ) : (
                <Payment
                  clientSecret={clientSecret}
                  stripePromise={stripePromise}
                  formData={formData}
                  onPaymentSuccess={handlePaymentSuccess}
                  onPaymentFailure={handlePaymentFailure}
                  isProcessing={isProcessing}
                  setIsProcessing={setIsProcessing}
                />
              )}
              {showPopup && (
                <SuccessPopup
                  header={t("successPopup.header")}
                  message={t("successPopup.message")}
                  onClose={handleClosePopup}
                />
              )}
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full items-center px-4 md:px-6 border-t">
        <p className="text-xs text-gray-500">
          &copy; 2024 Zeros2Heroes. {t("footer.terms")}
        </p>
        <nav className="sm:ml-auto flex gap-4">
          <FinePrint />
          <PrivacyPolicy />
        </nav>
      </footer>
    </div>
  );
}
