import React, { useEffect, useState } from "react";
import Enquiries from "./Enquiries";
import { SEOEnquiries } from "./SEOEnquiries";
import { Packages } from "./Packages";

const AdminPanel = () => {
  const [activeTab, setActiveTab] = useState("enquiries");
  return (
    <div className="admin-panel">
      {/* Tabs */}
      <div className="tabs flex gap-4">
        <button onClick={() => setActiveTab("enquiries")}>
          General Enquiries
        </button>
        <button onClick={() => setActiveTab("seoEnquiries")}>
          SEO Enquiries
        </button>
         <button onClick={() => setActiveTab("packages")}>
          Packages
        </button>
      </div>

      {/* Tab Content */}
      {activeTab === "enquiries" && <Enquiries />}
      {activeTab === "seoEnquiries" && <SEOEnquiries />}
      {activeTab === "packages" && <Packages />}
    </div>
  );
};

export default AdminPanel;
