import React from "react";
import { useTranslation } from "react-i18next";

export default function FAQ() {
  const { t } = useTranslation();
  const faqItems = t("faq.items", { returnObjects: true });

  return (
    <div id="faq" className="max-w-3xl mx-auto py-12">
      <h2 className="text-3xl font-bold text-center mb-8">{t("faq.title")}</h2>
      <div className="space-y-4">
        {faqItems.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
      </div>
    </div>
  );
}

function FAQItem({ question, answer }) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="border-b">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full text-left flex justify-between items-center py-4"
      >
        <span className="text-lg font-medium">{question}</span>
        <span>{isOpen ? "-" : "+"}</span>
      </button>
      {isOpen && <p className="text-gray-600 pb-4">{answer}</p>}
    </div>
  );
}