import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CheckCircle, Clock, BarChart } from "lucide-react";
import Benefits from "./components/Benefits";
import ReviewPlatforms from "./components/ReviewPlatforms";
import ImportanceOfCustomerReviews from "./components/ImportanceOfCustomerReviews";
import UnderstandingUnjustifiedReviews from "./components/UnderstandingUnjustifiedReviews";
import FAQ from "./components/FAQ";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import FinePrint from "../TermsConditions";
import PrivacyPolicy from "../PrivacyPolicy";
import { FlagIcon } from "react-flag-kit";
import Prices from "./Prices";

export default function LearnMore() {
  const { t, i18n } = useTranslation(); 
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <header className="px-4 mt-8 lg:px-6 h-14 flex items-center border-b justify-between">
        {/* Logo */}
        <Link to="/" className="flex items-center space-x-2">
          <img
            src={logo}
            alt="Logo"
            className="h-36 w-auto object-contain hover:opacity-80 transition"
          />
        </Link>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex md:items-center md:gap-6 lg:gap-8">
          {
          [
            "howItWorks",
            "benefits",
            "reviewPlatforms",
            "importance",
            "unjustifiedReviews",
            "faq",
          ].map((key) => (
            <a
              key={key}
              href={`#${key}`}
              className="text-sm font-medium hover:underline"
            >
              {t(`header.${key}`)}
            </a>
          ))}
          <div className="flex gap-1">
          <button
              onClick={() => changeLanguage("en")}
              className="focus:outline-none"
            >
              <FlagIcon code="GB" size={24} />
            </button>
            {/* German Flag */}
            <button
              onClick={() => changeLanguage("de")}
              className="focus:outline-none"
            >
              <FlagIcon code="DE" size={24} />
            </button>
          </div>
        </nav>

        {/* Hamburger Icon for Mobile */}
        <button onClick={toggleMenu} className="md:hidden">
          {menuOpen ? "✖" : "☰"}
        </button>
      </header>

      {/* Mobile Navigation Menu */}
      {menuOpen && (
        <nav
          className="md:hidden absolute top-14 left-0 w-3/4 bg-white shadow-lg p-4 z-50 rounded-lg"
          onClick={toggleMenu}
        >
          {[
            "howItWorks",
            "benefits",
            "reviewPlatforms",
            "importance",
            "unjustifiedReviews",
            "faq",
          ].map((key) => (
            <a
              key={key}
              href={`#${key}`}
              className="block text-sm font-medium py-2 hover:underline"
            >
              {t(`header.${key}`)}
            </a>
          ))}
        </nav>
      )}

      {/* Main Content */}
      <main className="flex-1">
        {/* Introduction Section */}
        <section className="w-full py-12 md:py-24 lg:py-32">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl">
                {t("introduction.title")}
              </h1>
              <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl">
                {t("introduction.description")}
              </p>
            </div>
          </div>
        </section>

        {/* How It Works Section */}
        <section
          id="howItWorks"
          className="w-full py-12 md:py-24 lg:py-32 bg-gray-100"
        >
          <div className="container px-4 md:px-6">
            <h2 className="text-3xl font-bold text-center mb-12">
              {t("howItWorks.header")}
            </h2>
            <div className="grid gap-6 lg:grid-cols-3">
              {t("howItWorks.steps", { returnObjects: true }).map(
                (step, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center bg-white rounded-lg shadow-md p-6"
                  >
                    {index === 0 && (
                      <CheckCircle className="h-8 w-8 text-black mb-4" />
                    )}
                    {index === 1 && (
                      <Clock className="h-8 w-8 text-black mb-4" />
                    )}
                    {index === 2 && (
                      <BarChart className="h-8 w-8 text-black mb-4" />
                    )}
                    <h3 className="text-lg font-semibold">{step.title}</h3>
                    <p className="text-gray-500 text-sm">{step.text}</p>
                  </div>
                )
              )}
            </div>
          </div>
        </section>

        {/* Other Sections */}
        <Benefits />
        <ReviewPlatforms />
        <ImportanceOfCustomerReviews />
        <UnderstandingUnjustifiedReviews />
        <FAQ />
        <Prices/>

        {/* Final CTA Section */}
        <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center text-center space-y-4">
              <h2 className="text-3xl font-bold">{t("finalCta.header")}</h2>
              <p className="text-gray-500 max-w-[600px]">
                {t("finalCta.description")}
              </p>
              <div className="space-x-4">
                <button
                  onClick={() => navigate("/seo#contact")}
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                  {t("finalCta.getStarted")}
                </button>
                <a
                  href="tel:+491632385858"
                  className="px-4 py-2 border border-blue-600 text-blue-600 rounded-lg hover:bg-blue-100"
                >
                  {t("finalCta.callUs")}
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="flex flex-col sm:flex-row py-6 w-full items-center px-4 md:px-6 border-t">
        <p className="text-xs text-gray-500">
          &copy; 2024 Zeros2Heroes. All rights reserved.
        </p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <FinePrint />
          <PrivacyPolicy />
        </nav>
      </footer>
    </div>
  );
}
