import React from "react";
import system1 from "../assets/system-1.png";
import system2 from "../assets/system-2.png";
import system3 from "../assets/system-3.png";
import { useTranslation } from "react-i18next";

const Process = () => {
  const { t } = useTranslation();
  const steps = [
    {
      image: system1,
      title: t('processStep1Header'),
      description: t('processStep1Description'),
    },
    {
      image: system2,
      title: t('processStep2Header'),
      description: t('processStep2Description'),
    },
    {
      image: system3,
      title: t('processStep3Header'),
      description: t('processStep3Description'),
    },
  ];

  return (
    <div id="sequence" className="flex flex-col items-center">
      <h1 className="font-poppins font-extrabold mb-6 text-3xl">{t('processHeader')}</h1>
      
      {/* Grid layout */}
      <div className="grid grid-cols-2 md:grid-cols-3 gap-6 justify-center mx-10">
        {/* First item */}
        <div className="relative flex flex-col items-center shadow-xl border rounded-md text-center p-4 w-full max-w-xs">
          <div className="absolute -top-6 bg-purple-600 text-white w-12 h-12 flex items-center justify-center rounded-full text-xl font-bold">
            1
          </div>
          <img src={steps[0].image} alt={`Step 1`} className="" />
          <h3 className="font-semibold mb-2">{steps[0].title}</h3>
          <p className="hidden md:block text-gray-600">{steps[0].description}</p>
        </div>

        {/* Second item */}
        <div className="relative flex flex-col items-center shadow-xl border rounded-md text-center p-4 w-full max-w-xs">
          <div className="absolute -top-6 bg-purple-600 text-white w-12 h-12 flex items-center justify-center rounded-full text-xl font-bold">
            2
          </div>
          <img src={steps[1].image} alt={`Step 2`} className="" />
          <h3 className="font-semibold mb-2">{steps[1].title}</h3>
          <p className="hidden md:block text-gray-600">{steps[1].description}</p>
        </div>

        {/* Third item */}
        <div className="relative flex flex-col items-center shadow-xl border rounded-md text-center p-4 w-full max-w-xs md:ml-0 ml-24">
          <div className="absolute -top-6 bg-purple-600 text-white w-12 h-12 flex items-center justify-center rounded-full text-xl font-bold">
            3
          </div>
          <img src={steps[2].image} alt={`Step 3`} className="" />
          <h3 className="font-semibold mb-2">{steps[2].title}</h3>
          <p className="hidden md:block text-gray-600">{steps[2].description}</p>
        </div>
      </div>
    </div>
  );
};

export default Process;