import React from "react";
import { useNavigate } from "react-router-dom";
import { BsCheckCircle } from "react-icons/bs";

const ThankYouPage = () => {
  const navigate = useNavigate();

  const handleReturnHome = () => {
    navigate("/");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-100 to-blue-50">
      <div className="max-w-md w-full space-y-8 p-10 bg-white rounded-xl shadow-lg">
        <div className="text-center">
          <BsCheckCircle className="mx-auto h-16 w-16 text-green-500" />
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            Thank You for Your Enquiry!
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Your payment has been successfully processed.
          </p>
          <p className="text-sm text-gray-600">
            Someone from our team will contact you within 24 hours.
          </p>
        </div>
        <div className="mt-8">
          <button
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition"
            onClick={handleReturnHome}
          >
            Return to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;