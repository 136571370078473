import React, { useState } from 'react';
import { FiChevronRight } from "react-icons/fi";
import { useTranslation } from "react-i18next"; // Importing the i18n hook

const FinePrint = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation(); // Using the translation function

  // Function to toggle modal visibility
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {/* Link to open the modal */}
      <div className='flex items-center'>
        <FiChevronRight className="text-blue-500 mr-2" />
        <a onClick={toggleModal} className="text-gray-800 hover:text-blue-500 cursor-pointer">
          {t('termsAndConditions')}
        </a>
      </div>

      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-[90%] md:w-[600px] p-6 rounded-lg shadow-lg relative">
            {/* Modal Header */}
            <div className="flex justify-between items-center border-b pb-3 mb-4">
              <h2 className="text-xl font-bold">{t("termsAndConditions")}</h2>
              <button
                onClick={toggleModal}
                className="text-gray-600 hover:text-gray-800 text-2xl"
              >
                &times;
              </button>
            </div>

            {/* Modal Content */}
            <div className="overflow-y-auto max-h-[400px] pr-4 text-left">
              {/* Loop through each header and content pair */}
              {[...Array(12)].map((_, index) => (
                <div key={index} className="mb-4">
                  <h3 className="font-bold text-lg mb-2">{t(`termsAndConditionsHeader${index + 1}`)}</h3>
                  <p className="text-gray-600">{t(`termsAndConditionsContent${index + 1}`)}</p>
                </div>
              ))}
            </div>

            {/* Modal Footer */}
            <div className="flex justify-end mt-4">
              <button
                onClick={toggleModal}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FinePrint;