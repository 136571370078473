import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FlagIcon } from "react-flag-kit"; 
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const { t, i18n } = useTranslation(); 
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const goToSeoBoost = () => {
    navigate("/seo");
    setDropdownOpen(false); 
  };
  // Function to change the language
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
 // Close the dropdown if clicked outside
 useEffect(() => {
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);

  return (
    <nav className="bg-white z-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center ">
          {/* Logo on the left */}
          <div className="">
            <a href="#home">
              <img
                src={logo}
                alt="Logo"
                className="w-36 rounded-lg -md:w-32 "
              />
            </a>
          </div>

          {/* Language Switcher on the right */}
          <div className="flex items-center space-x-4">
      
                  <div
                    onClick={goToSeoBoost}
                    className="cursor-pointer block px-4 py-2 text-gray-800 hover:bg-gray-100 hover:text-gray-600"
                  >
                    SEO Boost
                  </div>
       
       
            
            {/* English Flag */}
            <button
              onClick={() => changeLanguage("en")}
              className="focus:outline-none"
            >
              <FlagIcon code="GB" size={24} />
            </button>
            {/* German Flag */}
            <button
              onClick={() => changeLanguage("de")}
              className="focus:outline-none"
            >
              <FlagIcon code="DE" size={24} />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
