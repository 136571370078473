import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import PaymentDetailsForm from "./payments/PaymentDetailsForm";

const Payment = ({
  stripePromise,
  clientSecret,
  formData,
  onPaymentSuccess,
  onPaymentFailure,
  isProcessing,
  setIsProcessing,
}) => {
  const handlePaymentSuccess = (paymentIntent) => {
    console.log("Payment succeeded:", paymentIntent);
    if (onPaymentSuccess) onPaymentSuccess(paymentIntent, formData);
  };

  const handlePaymentFailure = (error) => {
    console.error("Payment failed:", error);
    if (onPaymentFailure) onPaymentFailure(error);
  };

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <PaymentDetailsForm
        isProcessing={isProcessing}
        setIsProcessing={setIsProcessing}
        formData={formData}
        onPaymentSuccess={handlePaymentSuccess}
        onPaymentFailure={handlePaymentFailure}
      />
    </Elements>
  );
};

export default Payment;
