import React from "react";
import background from "../assets/regionale-bg.jpg";
import mapCol from "../assets/map-col1.png";
import { useTranslation } from "react-i18next";

const Maps = () => {
  const { t } = useTranslation(); // useTranslation hook to access translation

  const handleScrollToPrices = () => {
    const prices = document.getElementById("prices");
    if (prices) {
      prices.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      id="home"
      className="flex flex-col  bg-top  " // Added padding for both mobile and desktop
      style={{ backgroundImage: `url(${background})` }}
    >
      
      {/* Semi-transparent overlay */}
      {/* <div className="absolute inset-0 bg-black opacity-30 z-1"></div> */}


        {/* Left image */}
        <div className="">
          <img className="" src={mapCol} alt="Map" />
        </div>

        {/* Right content */}
        <div className="text-left text-white ml-10">
          <h1 className="font-extrabold text-4xl mb-4">
            {t('mapsHeader')} {/* Translation for the header */}
          </h1>
          <p className="mb-6 text-lg leading-relaxed">
            {t('mapsDescription')} {/* Translation for the first paragraph */}
          </p>
          <p className="hidden md:block mb-8 text-lg leading-relaxed">
            {t('mapsDescription2')} {/* Translation for the second paragraph */}
          </p>
          <button
            onClick={handleScrollToPrices}
            className="mb-5 mt-4 w-40 bg-yellow-500 text-white font-bold py-2 px-6 hover:bg-yellow-600 transition duration-300"
          >
            {t('prices')} {/* Translation for the button */}
          </button>
        </div>
      
    </div>
  );
};

export default Maps;