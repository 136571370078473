import React, { useState } from "react";
import { FaCheckCircle, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Prices = () => {
  const { t, i18n } = useTranslation();

  const pricingData = {
    Google: [
      {
        plan: "GOOGLE STARTER",
        price: "32,00 €",
        reviews: "10 "+t('reviews'),
        isPopular: false,
        features: [
          t('features.perReview', { price: '32 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
        ],
      },
      {
        plan: "GOOGLE BASIC",
        price: "30,00 €",
        reviews: String(25) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '30 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.personalAdvisor'),
        ],
      },
      {
        plan: "GOOGLE PREMIUM",
        price: "27,00 €",
        reviews: String(50) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '27 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.personalAdvisor'),
        ],
        isPopular: true,
      },
      {
        plan: "GOOGLE GOLD",
        price: "25,00 €",
        reviews: String(100) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '25 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.personalAdvisor'),
        ],
      },
    ],
    TrustPilot: [
      {
        plan: "TRUSTPILOT STARTER",
        price: "30,00 €",
        reviews: String(10) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '30 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
        ],
        isPopular: false,
      },
      {
        plan: "TRUSTPILOT BASIC",
        price: "28,00 €",
        reviews: String(25) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '28 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.personalAdvisor'),
        ],
        isPopular: true,
      },
      {
        plan: "TRUSTPILOT PREMIUM",
        price: "26,00 €",
        reviews: String(50) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '26 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
        isPopular: false,
      },
      {
        plan: "TRUSTPILOT GOLD",
        price: "24,00 €",
        reviews: String(100) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '24 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
        isPopular: false,
      },
    ],
    ProvenExpert: [
      {
        plan: "PROVEN EXPERT STARTER",
        price: "24,00 €",
        reviews: String(10) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '24 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
        ],
      },
      {
        plan: "PROVEN EXPERT BASIC",
        price: "22,00 €",
        reviews: String(25) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '22 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
        ],
        isPopular: true,

      },
      {
        plan: "PROVEN EXPERT PREMIUM",
        price: "21,00 €",
        reviews: String(50) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '21 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
      },
      {
        plan: "PROVEN EXPERT GOLD",
        price: "19,50 €",
        reviews: String(100) + ' ' + t('reviews'),
        features: [
          "19,5 € per review",
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
      },
    ],
    LocalGuide: [
      {
        plan: "LOCAL GUIDE STARTER",
        price: "31,00 €",
        reviews: String(10) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '31 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
        ],
      },
      {
        plan: "LOCAL GUIDE BASIC",
        price: "29,00 €",
        reviews: String(25) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '29 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
        ],
        isPopular: true,
      },
      {
        plan: "LOCAL GUIDE PREMIUM",
        price: "27,00 €",
        reviews: String(50) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '27 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
      },
      {
        plan: "LOCAL GUIDE GOLD",
        price: "25,00 €",
        reviews: String(100) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '25 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
      },
    ],
    Immobilienscout24: [
      {
        plan: "IMMOSCOUT24 STARTER",
        price: "24,00 €",
        reviews: String(10) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '24 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
        ],
      },
      {
        plan: "IMMOSCOUT24 BASIC",
        price: "22,00 €",
        reviews: String(25) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '22 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
        ],
        isPopular: true,
      },
      {
        plan: "IMMOSCOUT24 PREMIUM",
        price: "21,00 €",
        reviews: String(50) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '21 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
      },
      {
        plan: "IMMOSCOUT24 GOLD",
        price: "19,50 €",
        reviews: String(100) + ' ' + t('reviews'),
        features: [
          "19,5 € per review",
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
      },
    ],
    Kununu: [
      {
        plan: "KUNUNU STARTER",
        price: "24,00 €",
        reviews: String(10) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '24 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
        ],
        isPopular: false,
      },
      {
        plan: "KUNUNU BASIC",
        price: "22,00 €",
        reviews: String(25) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '22 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.personalAdvisor'),
        ],
        isPopular: true,
      },
      {
        plan: "KUNUNU PREMIUM",
        price: "21,00 €",
        reviews: String(50) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '21 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
        isPopular: false,
      },
      {
        plan: "KUNUNU GOLD",
        price: "19,50 €",
        reviews: String(100) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '19,50 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
        isPopular: false,
      },
    ],
    HolidayCheck: [
      {
        plan: "HOLIDAYCHECK STARTER",
        price: "24,00 €",
        reviews: String(10) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '24 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
        ],
        isPopular: false,
      },
      {
        plan: "HOLIDAYCHECK BASIC",
        price: "22,00 €",
        reviews: String(25) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '22 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.personalAdvisor'),
        ],
        isPopular: true,
      },
      {
        plan: "HOLIDAYCHECK PREMIUM",
        price: "21,00 €",
        reviews: 50 +"  "+ t('reviews'),
        features: [
          t('features.perReview', { price: '21 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
        isPopular: false,
      },
      {
        plan: "HOLIDAYCHECK GOLD",
        price: "19,50 €",
        reviews: String(100) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '19,50 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
        isPopular: false,
      },
    ],

    Facebook: [
      {
        plan: "FACEBOOK STARTER",
        price: "24,00 €",
        reviews: String(10) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '24 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
        ],
        isPopular: false,
      },
      {
        plan: "FACEBOOK BASIC",
        price: "22,00 €",
        reviews: String(25) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '22 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
        ],
        isPopular: true,
      },
      {
        plan: "FACEBOOK PREMIUM",
        price: "21,00 €",
        reviews: String(50) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '21 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
        isPopular: false,
      },
      {
        plan: "FACEBOOK GOLD",
        price: "19,50 €",
        reviews: String(100) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '19,50 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
        isPopular: false,
      },
    ],
    Travelocity: [
      {
        plan: "IDEALO STARTER",
        price: "24,00 €",
        reviews: String(10) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '24 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
        ],
        isPopular: false,
      },
      {
        plan: "IDEALO BASIC",
        price: "22,00 €",
        reviews: String(25) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '22 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
        ],
        isPopular: true,
      },
      {
        plan: "PREMIUM",
        price: "21,00 €",
        reviews: String(50) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '21 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
        isPopular: false,
      },
      {
        plan: "IDEALO GOLD",
        price: "19,50 €",
        reviews: String(100) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '19,50 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
        isPopular: false,
      },
    ],
    'Mobile.de': [
      {
        plan: "MOBILE.DE STARTER",
        price: "24,00 €",
        reviews: String(10) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '24 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
        ],
        isPopular: false,
      },
      {
        plan: "MOBILE.DE BASIC",
        price: "22,00 €",
        reviews: String(25) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '22 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.personalAdvisor'),
        ],
        isPopular: true,
      },
      {
        plan: "MOBILE.DE PREMIUM",
        price: "21,00 €",
        reviews: String(50) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '21 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
        isPopular: false,
      },
      {
        plan: "MOBILE.DE GOLD",
        price: "19,50 €",
        reviews: String(100) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '19,50 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
        isPopular: false,
      },
    ],
    GoLocal: [
      {
        plan: "GOLOCAL STARTER",
        price: "24,00 €",
        reviews: String(10) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '24 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
        ],
        isPopular: false,
      },
      {
        plan: "GOLOCAL BASIC",
        price: "22,00 €",
        reviews: String(25) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '22 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.personalAdvisor'),
        ],
        isPopular: true,
      },
      {
        plan: "GOLOCAL PREMIUM",
        price: "21,00 €",
        reviews: String(50) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '21 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
        isPopular: false,
      },
      {
        plan: "GOLOCAL GOLD",
        price: "19,50 €",
        reviews: String(100) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '19,50 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
        isPopular: false,
      },
    ],
    TrustedShops: [
      {
        plan: "TRUSTED SHOPS STARTER",
        price: "24,00 €",
        reviews: String(10) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '24 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
        ],
        isPopular: false,
      },
      {
        plan: "TRUSTED SHOPS BASIC",
        price: "22,00 €",
        reviews: String(25) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '22 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.personalAdvisor'),
        ],
        isPopular: true,
      },
      {
        plan: "TRUSTED SHOPS PREMIUM",
        price: "21,00 €",
        reviews: String(50) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '21 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
        isPopular: false,
      },
      {
        plan: "TRUSTED SHOPS GOLD",
        price: "19,50 €",
        reviews: String(100) + ' ' + t('reviews'),
        features: [
          t('features.perReview', { price: '19,50 €' }),
           t('features.verifiedUsers'),
          t('features.dashboard'),
           t('features.moneyBackGuarantee'),
           t('features.twentyFourSevenPersonalAdvisor'),
           t('features.personalEditorTextCreator'),
        ],
        isPopular: false,
      },
    ],
    
      
  };



  const [selectedPlatform, setSelectedPlatform] = useState("Google");

  const currentPlans = pricingData[selectedPlatform]; // Get plans based on selected platform
 // State to track current card being displayed
 const [currentIndex, setCurrentIndex] = useState(0);

 // Function to go to the next plan
 const nextPlan = () => {
   setCurrentIndex((prevIndex) =>
     prevIndex === currentPlans.length - 1 ? 0 : prevIndex + 1
   );
 };

 // Function to go to the previous plan
 const prevPlan = () => {
   setCurrentIndex((prevIndex) =>
     prevIndex === 0 ? currentPlans.length - 1 : prevIndex - 1
   );
 };

 return (
   <div id="prices" className="px-4">
     {/* Selection Menu */}
     <div className="flex justify-center space-x-4 my-6 flex-wrap">
       {Object.keys(pricingData).map((platform) => (
         <button
           key={platform}
           className={`px-4 py-2 border ${
             selectedPlatform === platform
               ? "bg-black text-white"
               : "text-black"
           }`}
           onClick={() => {
             setSelectedPlatform(platform);
             setCurrentIndex(0); // Reset to first plan when changing platform
           }}
         >
           {platform}
         </button>
       ))}
     </div>

     {/* Title and Description */}
     <h1 className="text-center font-extrabold text-4xl">{t("prices")}</h1>
     <p className="text-center text-gray-500 mb-8">{t("pricesDescription")}</p>

     {/* Pricing Cards for Desktop */}
     <div className="hidden md:grid p-6 grid-cols-4 gap-4">
       {currentPlans.map((plan, index) => (
         <div
           key={index}
           className={`flex flex-col items-center border rounded-lg shadow-lg p-6 text-center ${
             plan.isPopular ? "bg-customPurple text-white" : "bg-white"
           }`}
           style={{ width: "300px", height: "600px" }} // Adjusted width and height
         >
           {plan.isPopular && (
             <span className="top-0 right-0 bg-customPurple text-white py-1 px-4 rounded-bl-lg">
               {t("popular")}
             </span>
           )}

           <h3
             className={`text-xl font-bold mb-2 ${
               plan.isPopular ? "text-white" : "text-customPurple"
             }`}
           >
             {plan.plan}
           </h3>
           <p
             className={`text-2xl font-bold mb-2 ${
               plan.isPopular ? "text-white" : "text-customPurple"
             }`}
           >
             {plan.price}
           </p>
           <p
             className={`text-sm mb-4 ${
               plan.isPopular ? "text-white" : "text-customPurple"
             }`}
           >
             {plan.reviews}
           </p>

           {/* Features */}
           <ul className="text-sm mb-6">
             {plan.features.map((feature, featureIndex) => (
               <li
                 key={featureIndex}
                 className={`flex items-center justify-start ${
                   plan.isPopular ? "text-white" : "text-gray-700"
                 }`}
               >
                 {plan.isPopular && (
                   <FaCheckCircle className="mr-2 text-white" />
                 )}
                 {feature}
               </li>
             ))}
           </ul>

           {/* Book Now Button */}
           <a
             href="#enquiry"
             className={`px-6 py-2 w-full text-center font-bold border-2 rounded-lg ${
               plan.isPopular
                 ? "bg-white text-customPurple border-white"
                 : "bg-customPurple text-white border-customPurple"
             } hover:bg-customPurple hover:text-white transition duration-300`}
           >
             {t("booknow")}
           </a>
         </div>
       ))}
     </div>

     {/* Pricing Cards with Arrows for Mobile (Below md breakpoint) */}
     <div className="md:hidden relative flex justify-center items-center">
       {/* Left Arrow */}
       <button
         onClick={prevPlan}
         className="absolute left-0 transform -translate-y-1/2 top-1/2 bg-gray-300 p-2 rounded-full"
       >
         <FaChevronLeft />
       </button>

       {/* Single card on mobile */}
       <div
         className={`flex flex-col items-center border rounded-lg shadow-lg p-6 text-center ${
           currentPlans[currentIndex].isPopular
             ? "bg-customPurple text-white"
             : "bg-white"
         }`}
         style={{ width: "300px", height: "600px" }} // Adjusted width and height for mobile
       >
         {currentPlans[currentIndex].isPopular && (
           <span className="absolute top-0 right-0 bg-customPurple text-white py-1 px-4 rounded-bl-lg">
             {t("popular")}
           </span>
         )}

         <h3
           className={`text-xl font-bold mb-2 ${
             currentPlans[currentIndex].isPopular
               ? "text-white"
               : "text-customPurple"
           }`}
         >
           {currentPlans[currentIndex].plan}
         </h3>
         <p
           className={`text-2xl font-bold mb-2 ${
             currentPlans[currentIndex].isPopular
               ? "text-white"
               : "text-customPurple"
           }`}
         >
           {currentPlans[currentIndex].price}
         </p>
         <p
           className={`text-sm mb-4 ${
             currentPlans[currentIndex].isPopular
               ? "text-white"
               : "text-customPurple"
           }`}
         >
           {currentPlans[currentIndex].reviews}
         </p>

         {/* Features */}
         <ul className="text-sm mb-6">
           {currentPlans[currentIndex].features.map((feature, featureIndex) => (
             <li
               key={featureIndex}
               className={`flex items-center justify-start ${
                 currentPlans[currentIndex].isPopular
                   ? "text-white"
                   : "text-gray-700"
               }`}
             >
               {currentPlans[currentIndex].isPopular && (
                 <FaCheckCircle className="mr-2 text-white" />
               )}
               {feature}
             </li>
           ))}
         </ul>

         {/* Book Now Button */}
         <a
           href="#enquiry"
           className={`px-6 py-2 w-full text-center font-bold border-2 rounded-lg ${
             currentPlans[currentIndex].isPopular
               ? "bg-white text-customPurple border-white"
               : "bg-customPurple text-white border-customPurple"
           } hover:bg-customPurple hover:text-white transition duration-300`}
         >
           {t("booknow")}
         </a>
       </div>

       {/* Right Arrow */}
       <button
         onClick={nextPlan}
         className="absolute right-0 transform -translate-y-1/2 top-1/2 bg-gray-300 p-2 rounded-full"
       >
         <FaChevronRight />
       </button>
     </div>
   </div>
 );
};

export default Prices;