import React from "react";
import girl from "../assets/a1.png";
import fiveStars from "../assets/a2.png";
import a3 from "../assets/a3.png";
import steigern from "../assets/a4.png";
import background from "../assets/sterneback.jpg";

import { useTranslation } from "react-i18next"; // Importing the i18n hook

const Banner = () => {
  const { t, i18n } = useTranslation(); // Using the i18n instance to change language

  const handleScrollToPrices = () => {
    const prices = document.getElementById("prices");
    if (prices) {
      prices.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      id="home"
      className="relative bg-cover bg-center z-20" // Added padding for both mobile and desktop
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="flex flex-col -z-40 md:flex-row md:text-left px-4 md:px-20">
        {/* Text Section */}

        <div className="textPart flex flex-col  md:text-left items-center md:items-start">
          <h1 className="text-2xl md:text-4xl md:mt-8 font-sans font-extrabold leading-tight mb-4">
            {t("bannerHeader")}
          </h1>
          {/* Description visible only on larger screens */}
          <p className="hidden md:block md:m-auto font-medium mb-6 lg:text-xl">
            {t("bannerDescription")}
          </p>
          <button
            onClick={handleScrollToPrices}
            className="hidden md:block w-24 mb-7 bg-yellow-500 text-white font-bold py-2 px-6 rounded-lg hover:bg-yellow-600 transition duration-300"
          >
            {t("prices")}
          </button>
        </div>

        <div className="imagesPart flex flex-col items-center relative">
          <img
            src={fiveStars}
            className="md:hidden w-1/2 md:w-1/3 mb-2 "
            alt="Five Stars"
          />
          <img src={girl} className="z-10" alt="Girl" />
          <img
            src={a3}
            className="hidden md:block absolute bottom-0 left-0  lg:w-28 z-0"
            alt="a3"
          />
      
          <div className="hidden md:block absolute bottom-10 right-10 z-20">
            <img src={steigern} className="w-24" alt="Steigern" />
          </div>
          <img
            src={fiveStars}
            className="absolute hidden bottom-0 right-12 md:block w-1/2 md:w-1/3 mb-2 "
            alt="Five Stars"
          />
          <button
            onClick={handleScrollToPrices}
            className="block md:hidden w-24 mb-7 bg-yellow-500 text-white font-bold py-2 px-6 rounded-lg hover:bg-yellow-600 transition duration-300"
          >
            {t("prices")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
