import React, { useRef } from "react";
import { FaCheckCircle, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import trustpilot from "../assets/trustpilot-logo.png"; // Replace with actual image paths
import provenExpert from "../assets/provenexpert.png"; // Replace with actual image paths
import loc from "../assets/localguide.jpg"; // Replace with actual image paths
import googleReviews from "../assets/google-maps.png";
import { useTranslation } from "react-i18next";

const ExpertsSection = () => {
  const scrollRef = useRef(null);
  const { t, i18n } = useTranslation();
  // Function to scroll left
  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
  };

  // Function to scroll right
  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
  };
  const handleScrollToPrices = () => {
    const prices = document.getElementById("prices");
    if (prices) {
      prices.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="hidden md:block md:flex flex-col md:flex-row justify-between items-start p-10 w-full bg-white">
      {/* Left Text Section */}
      <div className="w-full md:w-2/3 text-left">
        <h1 className="text-4xl font-extrabold mb-6">
          {t("expertsHeader")
            .split("\n")
            .map((text, index) => (
              <React.Fragment key={index}>
                {text}
                <br />
              </React.Fragment>
            ))}
        </h1>
        <p className="text-gray-600 text-lg mb-6">{t("expertsDescription")}</p>

        {/* Features List */}
        <ul className="space-y-4">
          <li className="flex items-center text-lg">
            <FaCheckCircle className="text-blue-500 mr-2" />
            {t("expertsCheck1")}
          </li>
          <li className="flex items-center text-lg">
            <FaCheckCircle className="text-blue-500 mr-2" />
            {t("expertsCheck2")}
          </li>
          <li className="flex items-center text-lg">
            <FaCheckCircle className="text-blue-500 mr-2" />
            {t("expertsCheck3")}
          </li>
        </ul>
        <p>{t("platforms")}</p>
      </div>

      {/* Right Card Section with Arrows */}
      <div className="relative w-full md:w-1/3 flex items-center">
        {/* Left Arrow */}
        <button
          onClick={scrollLeft}
          className="absolute left-0 z-10 bg-gray-200 hover:bg-gray-300 rounded-full p-2"
        >
          <FaChevronLeft className="text-lg" />
        </button>

        {/* Cards with horizontal scroll */}
        <div
          ref={scrollRef}
          className="w-full overflow-x-scroll whitespace-nowrap scroll-smooth py-6 space-x-4"
        >
          {/* Google Reviews Card */}
          <div
            onClick={handleScrollToPrices}
            className="inline-block bg-white rounded-lg shadow-lg p-6 text-center w-60"
          >
            <img
              src={googleReviews}
              alt="GoogleReviews"
              className="mx-auto mb-4"
            />
            <h3 className="text-lg font-bold">{t('googleMaps')}</h3>
            <div className="mt-4">
              <button className="text-gray-600 hover:text-black text-xl">
                →
              </button>
            </div>
          </div>
          {/* Trustpilot Card */}
          <div
            onClick={handleScrollToPrices}
            className="inline-block bg-white rounded-lg shadow-lg p-6 text-center w-60"
          >
            <img src={trustpilot} alt="Trustpilot" className="mx-auto mb-4" />
            <h3 className="text-lg font-bold">{t('TrustPilot')}</h3>
            <div className="mt-4">
              <button className="text-gray-600 hover:text-black text-xl">
                →
              </button>
            </div>
          </div>

          {/* Proven Expert Card */}
          <div
            onClick={handleScrollToPrices}
            className="inline-block bg-white rounded-lg shadow-lg p-6 text-center w-60"
          >
            <img
              src={provenExpert}
              alt="Proven Expert"
              className="w-auto h-auto mx-auto mb-4"
            />
            <h3 className="text-lg font-bold">Proven Expert</h3>
            <div className="mt-4">
              <button className="text-gray-600 hover:text-black text-xl">
                →
              </button>
            </div>
          </div>

          {/* Local Guide Card */}
          <div className="inline-block bg-white rounded-lg shadow-lg p-6 text-center w-60">
            <img
              src={loc}
              alt="Local Guide"
              className="w-24 h-auto mx-auto mb-4"
            />
            <h3 className="text-lg font-bold">Local Guide</h3>
            <div className="mt-4">
              <button
                onClick={handleScrollToPrices}
                className="text-gray-600 hover:text-black text-xl"
              >
                →
              </button>
            </div>
          </div>

          {/* Add more cards if needed */}
        </div>

        {/* Right Arrow */}
        <button
          onClick={scrollRight}
          className="absolute right-0 z-10 bg-gray-200 hover:bg-gray-300 rounded-full p-2"
        >
          <FaChevronRight className="text-lg" />
        </button>
      </div>
    </div>
  );
};

export default ExpertsSection;
