
import React from "react";
import { Star, TrendingUp, Users, Shield } from "lucide-react";
import { useTranslation } from "react-i18next";

export default function Benefits() {
  const { t } = useTranslation();
  const benefits = t("benefits.items", { returnObjects: true });

  
  return (
    <section id="benefits" className="w-full py-12 md:py-24 lg:py-32">
      <div className="container px-4 md:px-6">
        <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">
          {t("benefits.title")}
        </h2>
        <div className="grid gap-6 lg:grid-cols-2 lg:gap-12">
          {benefits.map((benefit, index) => (
            <div key={index} className="flex flex-col bg-white rounded-lg shadow-md p-6">
              {index === 0 && <Star className="h-8 w-8 text-black mb-4" />}
              {index === 1 && <TrendingUp className="h-8 w-8 text-black mb-4" />}
              {index === 2 && <Users className="h-8 w-8 text-black mb-4" />}
              {index === 3 && <Shield className="h-8 w-8 text-black mb-4" />}
              <h3 className="text-left text-lg font-semibold">{benefit.title}</h3>
              <p className="text-left text-gray-700 text-sm">{benefit.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
