import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { loadStripe } from "@stripe/stripe-js";
import Payment from "./Payment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Enquiry = () => {
  const { t } = useTranslation();
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const [clientSecret, setClientSecret] = useState("");
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [packages, setPackages] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const [formData, setFormData] = useState({
    package: "",
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
    googleEntry: "",
    subscribeNewsletter: false,
  });
  const navigate = useNavigate();
  const selectedPackage = packages.find((pkg) => pkg.name === formData.package);
  const selectedPackagePrice = selectedPackage ? selectedPackage.price : 0;
  // Fetch available packages
  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/packages`
        );
        setPackages(response.data);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };

    fetchPackages();
  }, []);

  // Generate Stripe Payment Intent
  useEffect(() => {
    const fetchPaymentIntent = async () => {
      if (!selectedPackagePrice) {
        console.log("No package price selected yet.");
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/create-payment-intent`,
          {
            amount: Math.round(selectedPackagePrice * 100), // Convert to cents
            currency: "eur", // Adjust currency as needed
          }
        );

        if (response.data && response.data.clientSecret) {
          console.log(
            "Payment Intent Client Secret:",
            response.data.clientSecret
          );
          setClientSecret(response.data.clientSecret); // Assume `setClientSecret` is used for Stripe's clientSecret
        } else {
          console.error("No clientSecret received from backend.");
        }
      } catch (error) {
        console.error("Error fetching payment intent:", error.message);
      }
    };

    fetchPaymentIntent();
  }, [selectedPackagePrice]);
 
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const handleCheckout = (e) => {
    e.preventDefault();
    if (!formData.googleEntry.trim()) {
      setErrorMessage(t("googleEntryRequired")); 
      return;
    }
    setErrorMessage("");
    setShowPaymentForm(true);
  };

  const handlePaymentSuccess = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/enquiry`,
          formData
        );
        console.log("Enquiry submitted successfully:", response.data);
        navigate("/thank-you"); // Redirect to a thank-you page
      } catch (error) {
        console.error("Error submitting enquiry:", error);
        toast.error("Failed to submit enquiry. Please contact support.");
      }
    };

  const handlePaymentFailure = (error) => {
    console.error("Payment failed:", error);
  };

  return (
    <div className="flex flex-col md:flex-row-reverse mx-10">
      <div className="flex flex-col text-left font-poppins justify-center mb-8 md:ml-10">
        <h1 className="font-extrabold text-4xl text-gray-700 mb-3 md:text-5xl md:text-black">
          {t("contactUs")}
        </h1>
        <h2 className="font-semibold text-gray-500 leading-loose">
          {t("contactUsDescription")}
        </h2>
      </div>

      <div id="enquiry" className="flex flex-col items-start text-left">
        <div className="font-poppins">
          <h1 className="font-extrabold text-3xl">{t("enquiryHeader")}</h1>
          <h2 className="text-lg text-gray-500 leading-loose font-bold">
            {t("enquiryDescription")}
          </h2>
        </div>
        {!showPaymentForm ? (
          <form onSubmit={handleCheckout} className="space-y-6">
            <div>
              <label className="block text-left text-gray-500 text-sm font-semibold mb-2">
                {t("selectPackage")}
              </label>
              <select
                name="package"
                value={formData.package}
                onChange={handleInputChange}
                required
                className="block w-full p-3 border border-gray-300 rounded-md"
              >
                <option value="" disabled>
                  {t("selectPackagePlaceholder")}
                </option>
                {packages
                .filter(
                  (pkg) =>
                    pkg.name !== "Basic Package" &&
                  pkg.name !== "Elite Package" &&
                  pkg.name !== "Premium Package"
                )
                .map((pkg) => (
                  <option key={pkg.name} value={pkg.name}>
                    {pkg.name} | €{pkg.price.toFixed(2)}
                  </option>
                ))}
              </select>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                name="lastName"
                placeholder={t("surName")}
                value={formData.lastName}
                onChange={handleInputChange}
                required
                className="p-3 border border-gray-300 rounded-md w-full"
              />
              <input
                type="text"
                name="firstName"
                placeholder={t("name")}
                value={formData.firstName}
                onChange={handleInputChange}
                required
                className="p-3 border border-gray-300 rounded-md w-full"
              />
            </div>

            <div>
              <input
                type="email"
                name="email"
                placeholder={t("email")}
                value={formData.email}
                onChange={handleInputChange}
                required
                className="p-3 border border-gray-300 rounded-md w-full"
              />
            </div>

            <div className="flex items-center">
              <div className="flex-none">
                <select className="p-3 border border-gray-300 rounded-md">
                  <option value="+49">+49</option>
                  <option value="+1">+1</option>
                  <option value="+44">+44</option>
                </select>
              </div>
              <input
                type="tel"
                name="phone"
                placeholder={t("phone")}
                value={formData.phone}
                onChange={handleInputChange}
                required
                className="p-3 border border-gray-300 rounded-md w-full ml-2"
              />
            </div>

            <div>
              <label className="block text-sm font-semibold mb-2">
                {t("googleEntry")}
              </label>
              <textarea
                name="googleEntry"
                placeholder={t("placeholder")}
                value={formData.googleEntry}
                onChange={handleInputChange}
                className="p-3 border border-gray-300 rounded-md w-full h-24"
              />
              {errorMessage && (
                <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
              )}
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                name="subscribeNewsletter"
                checked={formData.subscribeNewsletter}
                onChange={handleInputChange}
                className="h-4 w-4 text-yellow-500 border-gray-300 rounded focus:ring-yellow-400"
              />
              <label className="ml-2 text-gray-600 text-sm">
                {t("subscribe")}
              </label>
            </div>

            <button
              type="submit"
              className="w-full bg-yellow-500 text-white p-3 rounded-md font-semibold hover:bg-yellow-600 transition"
            >
              {t("proceedToPayment")}
            </button>
          </form>
        ) : (
          <Payment
          clientSecret={clientSecret}
          stripePromise={stripePromise}
          formData={formData}
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentFailure={handlePaymentFailure}
          isProcessing={isProcessing} 
          setIsProcessing={setIsProcessing} 
          />
        )}
      </div>
    </div>
  );
};

export default Enquiry;
