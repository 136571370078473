import React from "react";
import { useTranslation } from "react-i18next";

const Cards = () => {
  const { t } = useTranslation(); // useTranslation hook to access translations

  const cards = [
    {
      title: t("card1Title"),
      description: t("card1Description"),
    },
    {
      title: t("card2Title"),
      description: t("card2Description"),
    },
    {
      title: t("card3Title"),
      description: t("card3Description"),
    },
    {
      title: t("card4Title"),
      description: t("card4Description"),
    },
  ];

  return (
    <div className="grid grid-cols-2 gap-8 p-8">
      {cards.map((card, index) => (
        <div
          key={index}
          className="border shadow-lg p-3 md:p-6 rounded-lg bg-white"
        >
          <h3 className="text-xl font-bold">{card.title}</h3>
          <p className="hidden md:block text-gray-600">{card.description}</p>
        </div>
      ))}
    </div>
  );
};

export default Cards;