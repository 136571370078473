import React from "react";
import { useTranslation } from "react-i18next";

const UnderstandingUnjustifiedReviews = () => {
  const { t } = useTranslation();

  return (
    <section id="unjustified-reviews" className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
      <div className="container px-4 md:px-6">
        <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">
          {t("unjustifiedReviews.header")}
        </h2>
        <div className="space-y-6 max-w-3xl mx-auto">
          <p className="text-gray-600 dark:text-gray-600">
            {t("unjustifiedReviews.description")}
          </p>
          <ul className="list-disc text-left pl-5 space-y-2 text-gray-500 dark:text-gray-600">
            {t("unjustifiedReviews.list", { returnObjects: true }).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <p className="text-left text-gray-500 dark:text-gray-600">
            {t("unjustifiedReviews.footer")}
          </p>
        </div>
      </div>
    </section>
  );
};

export default UnderstandingUnjustifiedReviews;