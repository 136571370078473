import "./App.css";
import Navbar from "./components/Navbar";
import Banner from "./components/Banner";
import ExpertsSection from "./components/ExpertsSection";
import Process from "./components/Process";
import Prices from "./components/price/Prices";
import Doctor from "./components/Doctor";
import Maps from "./components/Maps";
import Happy from "./components/happy/Happy";
import Enquiry from "./components/Enquiry";
import Footer from "./components/Footer";
import AdminPanel from "./components/admin/AdminPanel";
import ProtectedRoute from './components/admin/ProtectedRoute';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/admin/Login';
import { useState, useEffect } from "react";
import LandingPage from "./components/seo/LandingPage";
import LearnMore from "./components/seo/LearnMore";
import ThankYouPage from "./components/ThankYouPage";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function App() {
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    // Check for token in localStorage to determine if user is authenticated
    const token = localStorage.getItem('token');
    if (token) {
      setAuth(true);
    }
  }, []);

  return (
    <div className="App">
       <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      <Router>
        <Routes>
          {/* Login Route */}
          <Route path="/login" element={<Login setAuth={setAuth} />} />

          {/* Admin Panel Protected Route */}
          <Route path="/admin/panel" element={<ProtectedRoute><AdminPanel /></ProtectedRoute>} />

          {/* Main Public Routes */}
          <Route path="/" element={
            <>
           
              <Navbar />
              <Banner />
              <ExpertsSection />
              <Process />
              <Prices />
              <Doctor />
              <Maps />
              <Happy />
              <Enquiry />
              <Footer />
            </>
          } />
          <Route path="/seo" element={<LandingPage/>}/>
          <Route path="/learn-more" element={<LearnMore />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;