import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const Prices = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pricingPackages = [
    {
      title: t("seoPrices.basic.title"),
      description: t("seoPrices.basic.description"),
      price: t("seoPrices.basic.price"),
      detail: t("seoPrices.basic.detail"),
    },
    {
      title: t("seoPrices.elite.title"),
      description: t("seoPrices.elite.description"),
      price: t("seoPrices.elite.price"),
      detail: t("seoPrices.elite.detail"),
    },
    {
      title: t("seoPrices.premium.title"),
      description: t("seoPrices.premium.description"),
      price: t("seoPrices.premium.price"),
      detail: t("seoPrices.premium.detail"),
    },
  ];
  const handleBuyNow = () => {
    navigate("/seo#contact");
  };
  const sharedDetails = [
    t("seoPrices.shared.deletionRate"),
    t("seoPrices.shared.paymentPolicy"),
    t("seoPrices.shared.vat"),
  ];

  return (
    <section id="prices" className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
      <div className="container px-4 md:px-6">
        <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">
          {t("seoPrices.title")}
        </h2>
        <div className="grid gap-8 lg:grid-cols-3">
          {pricingPackages.map((pkg, index) => (
            <div key={index} className="border rounded-lg shadow-lg p-6 bg-white">
              <h3 className="text-xl font-bold mb-4">{pkg.title}</h3>
              <h3 className=" font-bold mb-4 text-gray-600">{pkg.description}</h3>
              <p className="text-lg font-semibold text-gray-800 mb-4">{pkg.price}</p>
              <p className="text-gray-600">{pkg.detail}</p>
            <button
                onClick={handleBuyNow}
                className="w-3/4 bg-blue-500  text-white py-2 px-4 rounded-md font-semibold hover:bg-blue-600 transition"
              >
                {t("booknow")}
              </button>
            </div>
          ))}
        </div>
        <div className="mt-8 border-t pt-6">
          <h4 className="text-lg font-bold text-center mb-4">
            {t("seoPrices.shared.title")}
          </h4>
          <ul className="list-disc text-center pl-5 space-y-2 text-gray-600 list-none">
            {sharedDetails.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Prices;