import React from "react";
import { useTranslation } from "react-i18next";

const ReviewPlatforms = () => {
  const { t } = useTranslation();
  const platforms = t("reviewPlatforms.platforms", { returnObjects: true });

  return (
    <section id="review-platforms" className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
      <div className="container px-4 md:px-6">
        <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">
          {t("reviewPlatforms.title")}
        </h2>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {platforms.map((platform, index) => (
            <div key={index} className="border rounded-lg p-6 shadow-lg bg-white">
              <h3 className="text-left text-xl font-semibold mb-2">{platform.title}</h3>
              <p className="text-left text-gray-600 dark:text-gray-700">{platform.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ReviewPlatforms;