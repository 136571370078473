import React from "react";

const SuccessPopup = ({ message,header, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-sm mx-auto text-center">
        <div className="text-green-500 text-5xl mb-4">
          {/* Icon */}
          <svg
            className="w-16 h-16 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2l4-4m-7 7l7-7"
            />
          </svg>
        </div>
        <h2 className="text-2xl font-semibold mb-2">{header}</h2>
        <p className="text-gray-600">{message}</p>
        <button
          onClick={onClose}
          className="mt-6 bg-purple-600 text-white font-semibold py-2 px-6 rounded-lg hover:bg-purple-700 transition duration-300"
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default SuccessPopup;