import React, { useEffect, useState } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";
import OpenAIPrompt from "./OpenApiPrompt";

const Enquiries =() => {
    const [enquiries, setEnquiries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const headers = [
        { label: "Package", key: "package" },
        { label: "First Name", key: "firstName" },
        { label: "Last Name", key: "lastName" },
        { label: "Email", key: "email" },
        { label: "Phone", key: "phone" },
        { label: "Google Entry", key: "googleEntry" },
        { label: "Subscribe To Newsletter", key: "subscribeNewsletter" },
        { label: "Date", key: "createdAt" },
      ];
      
      useEffect(() => {
        const fetchEnquiries = async () => {
          try {
            const token = localStorage.getItem("token"); // Get token from localStorage
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/api/enquiries`,
              {
                headers: {
                  Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                },
              }
            );
            setEnquiries(response.data);
            setLoading(false);
          } catch (err) {
            setError("Error fetching enquiries");
            setLoading(false);
          }
        };
    
        fetchEnquiries();
      }, []);
      if (loading) {
        return <div>Loading enquiries...</div>;
      }
    
      if (error) {
        return <div>{error}</div>;
      }
      const handleDelete = async (id) => {
        try {
          const token = localStorage.getItem("token"); // Get token from localStorage
          await axios.delete(
            `${process.env.REACT_APP_API_URL}/api/enquiries/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Include token in Authorization header
              },
            }
          );
          // Remove deleted enquiry from the state
          setEnquiries(enquiries.filter((enquiry) => enquiry._id !== id));
          alert("Enquiry deleted successfully");
        } catch (err) {
          alert("Error deleting enquiry");
        }
      };
    
      if (loading) {
        return <div>Loading enquiries...</div>;
      }
    
      if (error) {
        return <div>{error}</div>;
      }
    
      return (
        <div className="container mx-auto p-8">
          <h1 className="text-2xl font-bold mb-4">Admin Panel - Enquiries</h1>
          <table className="table-auto w-full border-collapse border border-gray-300 my-6">
            <thead>
              <tr>
                <th className="border border-gray-300 px-4 py-2">Package</th>
                <th className="border border-gray-300 px-4 py-2">First Name</th>
                <th className="border border-gray-300 px-4 py-2">Last Name</th>
                <th className="border border-gray-300 px-4 py-2">Email</th>
                <th className="border border-gray-300 px-4 py-2">Phone</th>
                <th className="border border-gray-300 px-4 py-2">Google Entry</th>
                <th className="border border-gray-300 px-4 py-2">
                  Subscribe Newsletter
                </th>
                <th className="border border-gray-300 px-4 py-2">Date</th>
              </tr>
            </thead>
            <tbody>
              {enquiries.map((enquiry) => (
                <tr key={enquiry._id}>
                  <td className="border border-gray-300 px-4 py-2">
                    {enquiry.package}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {enquiry.firstName}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {enquiry.lastName}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {enquiry.phone}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {enquiry.email}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {enquiry.googleEntry}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {enquiry.subscribeNewsletter ? "Yes" : "No"}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {new Date(enquiry.createdAt).toLocaleDateString()}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    <button
                      className="bg-red-500 text-white px-4 py-2 rounded"
                      onClick={() => handleDelete(enquiry._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* CSV Export Button */}
          <CSVLink
            data={enquiries}
            headers={headers}
            filename="enquiries.csv"
            className="bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Export Data to CSV
          </CSVLink>
          {/* <OpenAIPrompt/> */}
        </div>
      );
    };
    export default Enquiries;
