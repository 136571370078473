import React, { useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { useTranslation } from "react-i18next"; // Importing the i18n hook

const PrivacyPolicy = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(); // Using the translation function

  // Function to toggle modal visibility
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {/* Link to open the modal */}
      <div className="flex items-center">
        <FiChevronRight className="text-blue-500 mr-2" />
        <a
          href="#"
          onClick={toggleModal}
          className="text-gray-800 hover:text-blue-500"
        >
          {t("privacyPolicy")}
        </a>
      </div>
      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-[90%] md:w-[600px] p-6 rounded-lg shadow-lg relative">
            {/* Modal Header */}
            <div className="flex justify-between items-center border-b pb-3 mb-4">
              <h2 className="text-xl font-bold">{t("privacyPolicyOf")}</h2>
              <button
                onClick={toggleModal}
                className="text-gray-600 hover:text-gray-800 text-2xl"
              >
                &times;
              </button>
            </div>

            {/* Modal Content */}
            <div className="overflow-y-auto max-h-[500px] text-gray-600 pr-4 text-left font-extrabold">
              <h3 className="font-bold text-lg mb-3">
                {t("privacyPolicyHeader")}
              </h3>
              <p className="text-gray-600 mb-4">{t("privacyPolicyContent1")}</p>

              <p className="text-gray-600 mb-4">{t("privacyPolicyContent2")}</p>

              <ul className="list-disc ml-6  mb-4 leading-snug">
                <li>{t("privacyPolicyBullet1")}</li>
                <li>{t("privacyPolicyBullet2")}</li>
                <li>{t("privacyPolicyBullet3")}</li>
                <li>{t("privacyPolicyBullet4")}</li>
                <li>{t("privacyPolicyBullet5")}</li>
                <li>{t("privacyPolicyBullet6")}</li>
              </ul>
              <p className="mb-4">{t("privacyPolicyContent3")}</p>

              <ul className="list-disc ml-6  mb-4 leading-snug">
              <li>{t("privacyPolicyBullet7")}</li>
              <li>{t("privacyPolicyBullet8")}</li>
                </ul>
                <p className="text-gray-600 mb-4">{t("privacyPolicyContent5")}</p>
                <p className="text-gray-600 mb-4">{t("privacyPolicyContent6")}</p>
                <h2 className="text-xl font-bold my-4">{t("privacyPolicyScope")}</h2>
                <p className="text-gray-600 my-4">{t("privacyPolicyScopeContent")}</p>
                <h2 className="text-xl font-bold mb-4">cookies</h2>
                <p className="text-gray-600 my-4">{t("privacyPolicyScopeCoookies")}</p>
                <h2 className="text-xl font-bold mb-4">Google webfonts</h2>
                <p className="text-gray-600 my-4">{t("privacyPolicyGoogleWebFonts")}</p>
                <p className="text-gray-600 my-4">{t("privacyPolicyGoogleWebFonts2")}</p>
               <div className="flex flex-col mb-4">
                <a href="https://www.google.com/fonts#AboutPlace:about">https://www.google.com/fonts#AboutPlace:about</a>
                <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>
              </div>
                <h2 className="text-xl font-bold mb-4">Google Analytics</h2>
                <p className="text-gray-600 my-4">{t("privacyPolicyGooglAnalytics1")}</p>
                <p className="text-gray-600 my-4">{t("privacyPolicyGooglAnalytics2")}</p>
                <p className="text-gray-600 my-4">{t("privacyPolicyGooglAnalytics3")}</p>
                <p className="text-gray-600 my-4">{t("privacyPolicyGooglAnalytics4")}</p>
                <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>
                <p className="text-gray-600 my-4">{t("privacyPolicyGooglAnalytics5")}</p>
                <h2 className="text-xl font-bold mb-4">Google Tag Manager / Google Ads</h2>
                <p className="text-gray-600 my-4">{t("privacyPolicyGoogleTagManager1")}</p>
                <p className="text-gray-600 my-4">{t("privacyPolicyGoogleTagManager2")}</p>
                <p className="text-gray-600 my-4">{t("privacyPolicyGoogleTagManager3")}</p>
                <h2 className="text-xl font-bold mb-4">Paypal</h2>
                <p className="text-gray-600 my-4">{t("privacyPolicyPaypal1")}</p>
                <p className="text-gray-600 my-4">{t("privacyPolicyPaypal2")}</p>
                <h2 className="text-xl font-bold mb-4">{t("privacyPolicySecuritySSLHeader")}</h2>
                <p className="text-gray-600 my-4">{t("privacyPolicySecuritySSLContent1")}</p>
                <p className="text-gray-600 my-4">{t("privacyPolicySecuritySSLContent2")}</p>
                <p className="text-gray-600 my-4">{t("privacyPolicySecuritySSLContent3")}</p>
                <h2 className="text-xl font-bold mb-4">{t("privacyPolicyRightsOfThoseAffectedHeader")}</h2>
                <p className="text-gray-600 my-4">{t("privacyPolicyRightsOfThoseAffectedContent")}</p>
                <p className="text-gray-600 my-4">{t("privacyPolicyYourRights")}</p>
                <ul className="list-disc ml-6  mb-4 leading-loose">
                <li>{t("privacyPolicyYourRightsBullet1")}</li>
                <li>{t("privacyPolicyYourRightsBullet2")}</li>
                <li>{t("privacyPolicyYourRightsBullet3")}</li>
                <li>{t("privacyPolicyYourRightsBullet4")}</li>
                <li>{t("privacyPolicyYourRightsBullet5")}</li>
                <li>{t("privacyPolicyYourRightsBullet6")}</li>
                <li>{t("privacyPolicyYourRightsBullet7")}</li>
                <li>{t("privacyPolicyYourRightsBullet8")}</li>
                <li>{t("privacyPolicyYourRightsBullet9")}</li>
                <li>{t("privacyPolicyYourRightsBullet10")}</li>
                <li>{t("privacyPolicyYourRightsBullet11")}</li>
                <li>{t("privacyPolicyYourRightsBullet12")}</li>
                <li>{t("privacyPolicyYourRightsBullet13")}</li>
              </ul>
              <p className="text-gray-600 my-4">{t("privacyPolicyClosing")}</p>

              {/* Add more content as needed */}

            </div>

            {/* Modal Footer */}
            <div className="flex justify-end mt-4">
              <button
                onClick={toggleModal}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrivacyPolicy;
