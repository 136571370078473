import React from "react";
import { useTranslation } from "react-i18next";
import david from "../../../assets/man1.png";
import ethan from "../../../assets/man3.png";
import jessica from "../../../assets/woman2.png";

const Testimonials = () => {
  const { t } = useTranslation();

  const testimonials = [
    {
      name: t("testimonials.testimonialsList.0.name"),
      title: t("testimonials.testimonialsList.0.title"),
      testimonial: t("testimonials.testimonialsList.0.testimonial"),
      image: david,
    },
    {
      name: t("testimonials.testimonialsList.1.name"),
      title: t("testimonials.testimonialsList.1.title"),
      testimonial: t("testimonials.testimonialsList.1.testimonial"),
      image: ethan,
    },
    {
      name: t("testimonials.testimonialsList.2.name"),
      title: t("testimonials.testimonialsList.2.title"),
      testimonial: t("testimonials.testimonialsList.2.testimonial"),
      image: jessica,
    },
  ];

  return (
    <section id="testimonials" className="w-full py-12 md:py-24 lg:py-32">
      <div className="container mx-auto px-4 md:px-6">
        <h2 className="text-3xl font-bold tracking-tight sm:text-5xl text-center mb-12 text-gray-800">
          {t("testimonials.header")}
        </h2>
        <div className="grid gap-8 lg:grid-cols-3">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="flex flex-col items-center text-center space-y-4"
            >
              <img
                alt={testimonial.name}
                className="rounded-full w-24 h-24 object-cover"
                src={testimonial.image}
              />
              <div className="space-y-2">
                <h3 className="text-xl font-bold text-gray-800">
                  {testimonial.name}
                </h3>
                <p className="text-sm text-gray-500">{testimonial.title}</p>
                <p className="text-gray-600 italic">
                  "{testimonial.testimonial}"
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;