import React from "react";
import starReviews from '../../assets/starReviews.jpg';
import Cards from "./Cards";
import seperate from '../../assets/seperate.png';
import { useTranslation } from "react-i18next";

const Happy = () => {
  const { t } = useTranslation(); // useTranslation hook to access translation

  return (
    <div className="flex flex-col  items-center mt-10">
      <h1 className="font-extrabold text-2xl md:text-4xl mb-4">
        {t('happyHeader')} {/* Translation for the header */}
      </h1>
      <p className="text-gray-600 mb-9 mx-3">
        {t('happyDescription')} {/* Translation for the description */}
      </p>
      <div className="flex flex-col md:flex-row mx-4 ml-6">
        {/* Image Section */}
      
          <img className="md:w-1/3  rounded-lg shadow-lg" src={starReviews} alt="Star Reviews" />
    
        
        {/* Cards Section */}
        <div className="flex-grow mt-8">
          <Cards />
        </div>
      </div>
      <div className="mt-14 ml-14 w-[900px]">
      <img className="" src={seperate} alt="Seperator" />

      </div>
    </div>
  );
};

export default Happy;