import React, { useState } from 'react';
import axios from 'axios';

const OpenAIPrompt = () => {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      // Make a POST request to the backend route
      const res = await axios.post('/api/openai', { prompt });
      setResponse(res.data.response);
    } catch (err) {
      setError('Failed to get a response from OpenAI');
    }

    setLoading(false);
  };

  return (
    <div className="openai-prompt">
      <h1>Ask OpenAI</h1>
      <form onSubmit={handleSubmit}>
        <textarea
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Enter your prompt here"
          rows="4"
          className="border p-2 w-full"
        />
        <button type="submit" className="mt-2 bg-blue-500 text-white px-4 py-2 rounded">
          {loading ? 'Loading...' : 'Submit'}
        </button>
      </form>

      {error && <p className="text-red-500 mt-4">{error}</p>}

      {response && (
        <div className="response mt-4 p-4 bg-gray-100 border">
          <h3>Response:</h3>
          <p>{response}</p>
        </div>
      )}
    </div>
  );
};

export default OpenAIPrompt;