import React, { useEffect, useState } from "react";
import axios from "axios";

export const Packages = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newPackage, setNewPackage] = useState({ name: "", price: "" });

  const handleDelete = async (name) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/packages/${name}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPackages(packages.filter((pkg) => pkg.name !== name));
      alert("Package deleted successfully");
    } catch (err) {
      alert("Error deleting package");
    }
  };

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const token = localStorage.getItem("token"); // Authentication token
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/packages`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const sortedPackages = response.data.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
    
        setPackages(sortedPackages);
        setLoading(false);
      } catch (err) {
        setError("Error fetching packages");
        setLoading(false);
      }
    };

    fetchPackages();
  }, []);
  const handleAddPackage = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
       // Check if the package already exists
    const existingPackage = packages.find(
        (pkg) => pkg.name.toLowerCase() === newPackage.name.toLowerCase()
      );
  
      if (existingPackage) {
        // Delete the existing package
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/packages/${existingPackage.name}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        // Update local state
        setPackages(packages.filter((pkg) => pkg.name !== existingPackage.name));
      }
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/packages`,
        newPackage,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Update state with new package
      setPackages([...packages, response.data]);
      setNewPackage({ name: "", price: "" }); // Reset form
      alert("Package added successfully!");
    } catch (err) {
      alert("Error adding package");
    }
  };
  if (loading) {
    return <div>Loading packages...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-2xl font-bold mb-4">Admin Panel - Packages</h1>
      <form onSubmit={handleAddPackage} className="mb-6">
        <div className="flex flex-col space-y-4">
          <input
            type="text"
            placeholder="Package Name"
            value={newPackage.name}
            onChange={(e) => setNewPackage({ ...newPackage, name: e.target.value })}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
          />
          <input
            type="number"
            placeholder="Price (€)"
            value={newPackage.price}
            onChange={(e) => setNewPackage({ ...newPackage, price: e.target.value })}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
          />
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          >
            Add/Update Package
          </button>
        </div>
      </form>
      <table className="table-auto w-full border-collapse border border-gray-300 my-6">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">Package Name</th>
            <th className="border border-gray-300 px-4 py-2">Price (€)</th>
          </tr>
        </thead>
        <tbody>
          {packages.map((pkg) => (
            <tr key={pkg._id}>
              <td className="border border-gray-300 px-4 py-2">{pkg.name}</td>
              <td className="border border-gray-300 px-4 py-2">{pkg.price}</td>
              <button
                onClick={() => handleDelete(pkg.name)}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                Delete
              </button>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
