import React, { useEffect, useState } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";

export const SEOEnquiries = () => {
  const [seoEnquiries, setSeoEnquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Message", key: "message" },
    { label: "Package", key: "package" },
    { label: "Date", key: "createdAt" },
    { label: "Reviews", key: "amountReviews" },
  ];

  const fetchSeoEnquiries = async () => {
    try {
      const token = localStorage.getItem("token"); // Get token from localStorage
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/seo-enquiries`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        }
      );
      setSeoEnquiries(response.data);
      setLoading(false);
    } catch (err) {
      setError("Error fetching SEO enquiries");
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token"); // Get token from localStorage
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/seo-enquiries/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
          },
        }
      );
      // Remove deleted enquiry from the state
      setSeoEnquiries((prevEnquiries) =>
        prevEnquiries.filter((enquiry) => enquiry._id !== id)
      );
      alert("Enquiry deleted successfully");
    } catch (err) {
      alert("Error deleting enquiry");
    }
  };

  useEffect(() => {
    fetchSeoEnquiries();
  }, []);

  if (loading) {
    return <div>Loading SEO enquiries...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-2xl font-bold mb-4">Admin Panel - SEO Enquiries</h1>
      <table className="table-auto w-full border-collapse border border-gray-300 my-6">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">Name</th>
            <th className="border border-gray-300 px-4 py-2">Email</th>
            <th className="border border-gray-300 px-4 py-2">Message</th>
            <th className="border border-gray-300 px-4 py-2">Package</th>
            <th className="border border-gray-300 px-4 py-2">Date</th>
            <th className="border border-gray-300 px-4 py-2">Actions</th>
            <th className="border border-gray-300 px-4 py-2">Reviews</th>
          </tr>
        </thead>
        <tbody>
          {seoEnquiries.map((enquiry) => (
            <tr key={enquiry._id}>
              <td className="border border-gray-300 px-4 py-2">{enquiry.name}</td>
              <td className="border border-gray-300 px-4 py-2">{enquiry.email}</td>
              <td className="border border-gray-300 px-4 py-2">{enquiry.message}</td>
              <td className="border border-gray-300 px-4 py-2">{enquiry.package}</td>
              <td className="border border-gray-300 px-4 py-2">
                {new Date(enquiry.createdAt).toLocaleDateString()}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded"
                  onClick={() => handleDelete(enquiry._id)}
                >
                  Delete
                </button>
              </td>
              <td className="border border-gray-300 px-4 py-2">{enquiry.amountReviews}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* CSV Export Button */}
      <CSVLink
        data={seoEnquiries}
        headers={headers}
        filename="seo-enquiries.csv"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Export Data to CSV
      </CSVLink>
    </div>
  );
};