import React, { useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { useTranslation } from "react-i18next"; // Importing the i18n hook

const Imprint = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(); // Using the translation function

  // Function to toggle modal visibility
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {/* Link to open the modal */}
      <div className="flex items-center">
        <FiChevronRight className="text-blue-500 mr-2" />
        <a
          href="#"
          onClick={toggleModal}
          className="text-gray-800 hover:text-blue-500"
        >
          {t("imprint")}
        </a>
      </div>
      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-[90%] md:w-[600px] p-6 rounded-lg shadow-lg relative">
            {/* Modal Header */}
            <div className="flex justify-between items-center border-b pb-3 mb-4">
              <h2 className="text-xl font-bold">{t("imprintTitle")}</h2>
              <button
                onClick={toggleModal}
                className="text-gray-600 hover:text-gray-800 text-2xl"
              >
                &times;
              </button>
            </div>

            {/* Modal Content */}
            <div className="overflow-y-auto max-h-[400px] pr-4">
              <p className="text-gray-600 mb-4">
                <strong>{t("imprintInformation")}</strong>
                <br />
                zeros2heroes <br />
                Kirchplatz 10 <br />
                42651 Solingen, Germany
              </p>

              <p className="text-gray-600 mb-4">
                <strong>Contact:</strong>
                <br />
                Email: info@zeroes2heroes.net
              </p>

              <p className="text-gray-600 mb-4">
                {t("imprintResponsbile")}
                <br />
                {t("imprintAsAbove")}
              </p>

              <p className="text-gray-600 mb-4">
                <strong>{t("imprintEuDisputeHeader")}</strong>
                <br />
                {t("imprintEuDispute")}
              </p>

              <p className="text-gray-600 mb-4">
                <strong> {t("imprintLiabilityForContentHeader")}</strong>
                <br />
                {t("imprintLiabilityForContent")}
              </p>

              {/* Add more content as needed */}
              <p className="text-gray-600 mb-4">
                <strong>{t("imprintLiabilityForLinksHeader")}</strong>
                <br />
                {t("imprintLiabilityForLinksContent")}
              </p>

              <p className="text-gray-600 mb-4">
                <strong>{t("imprintCopyrightHeader")}</strong>
                <br />
                {t("imprintCopyrightContent")}
              </p>
            </div>

            {/* Modal Footer */}
            <div className="flex justify-end mt-4">
              <button
                onClick={toggleModal}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Imprint;
