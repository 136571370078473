import React from "react";
import doctorImage from "../assets/tm.png";
import { FaCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Doctor = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col mt-7 mx-4">
      <h1 className="font-poppin leading-10 tracking-tighter text-5xl font-extrabold mb-3">
        {t("yourSuccess")}
      </h1>
      <div className="flex flex-col md:flex-row">
        <img src={doctorImage} className="md:w-1/2 mx-9" alt="Doctor" />
        <div className="flex flex-col text-left">
          <p className="font-poppins mb-5 text-gray-500  ">
            {t("yourSuccessDescription1")}
          </p>

          <p className="mb-5 text-gray-500 ">{t("yourSuccessDescription2")}</p>
          <h1 className="font-poppins font-extrabold text-xl mb-3">
            {t("yourSales")}
          </h1>
          <ul className="space-y-4">
          {/* Acquiring new customers */}
          <li className="flex items-start">
            <FaCheckCircle className="text-pink-500 mt-1 mr-2 flex-shrink-0" />
            <div>
              <h3 className="font-semibold">{t("yourSales1Header")}</h3>
              <p className="text-gray-600">{t("yourSales1Description")}</p>
            </div>
          </li>

          {/* Recognizability */}
          <li className="flex items-start">
            <FaCheckCircle className="text-purple-600 mt-1 mr-2 flex-shrink-0" />
            <div>
              <h3 className="font-semibold">{t("yourSales2Header")}</h3>
              <p className="text-gray-600">{t("yourSales2Description")}</p>
            </div>
          </li>

          {/* Revenue Maximization */}
          <li className="flex items-start">
            <FaCheckCircle className="text-green-600 mt-1 mr-2 flex-shrink-0" />
            <div>
              <h3 className="font-semibold">{t("yourSales3Header")}</h3>
              <p className="text-gray-600">{t("yourSales3Description")}</p>
            </div>
          </li>
        </ul>
        </div>
       
      </div>
    </div>
  );
};

export default Doctor;
