import React from "react";
import logo from "../assets/logo.png";
import { FiChevronRight } from "react-icons/fi";
import FinePrint from "./TermsConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import Imprint from "./Imprint";
import mail from "../assets/mail.svg";
import phone from "../assets/phone.svg";
import { useTranslation } from "react-i18next"; // Importing the i18n hook

const Footer = () => {
  const { t } = useTranslation(); // Using the translation function

  return (
    <div className="grid md:grid-cols-4 mt-5">
      <div className="flex justify-center md:justify-start">
      <a href="#home">
        <img className="w-44 " src={logo} alt="Logo" />
      </a>
      </div>
      <div className="text-center md:text-left mb-2">
        <h1 className="font-poppins mb-3 font-extrabold text-xl">
          {t('ourStory')}
        </h1>
        <p className="font-poppins font-medium text-gray-500">
          {t('ourStoryDescription')}
        </p>
      </div>

      <div className="flex flex-col items-center">
        <h1 className="text-xl font-extrabold md:text-left mb-3">
          {t('quickLinks')}
        </h1>
        <ul className="leading-tight">
          <li className="flex items-center">
            <FiChevronRight className="text-blue-500 mr-2" />
            <a href="#home" className="text-gray-800 hover:text-blue-500">
              {t('Home')}
            </a>
          </li>
          <li className="flex items-center">
            <FiChevronRight className="text-blue-500 mr-2" />
            <a href="#sequence" className="text-gray-800 hover:text-blue-500">
              {t('sequence')}
            </a>
          </li>
          <li className="flex items-center">
            <FiChevronRight className="text-blue-500 mr-2" />
            <a href="#prices" className="text-gray-800 hover:text-blue-500">
              {t('prices')}
            </a>
          </li>
          <li>
            <FinePrint />
          </li>
          <li>
            <PrivacyPolicy />
          </li>
          <li>
            <Imprint />
          </li>
        </ul>
      </div>

      <div className="flex flex-col leading-loose items-center">
        <h1 className="font-extrabold text-left text-xl mb-2">
          {t('contactUs')}
        </h1>
        <div className="flex flex-row space-x-1">
          <img src={mail} alt="Email Icon" />
          <p>info@zeros2heroes.net</p>
        </div>
        <div className="flex flex-row space-x-1">
          <img src={phone} alt="Phone Icon" />
          <p>+49 163 238 5858</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;